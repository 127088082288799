import { ModalMain } from "../styled";
import { FontGreyDarkFullBold, FontGreyDarkFullRegular, FontGreyDarkMediumTitle } from "../../../../utils/typografyApp";
import SecondaryButton from "../../SecondaryButton";
import PrimaryButton from "../../PrimaryButton";
import { DeleteSectionForm } from "./styled";
import { useDeleteSectionModalHook } from "./hook";
import { FormProvider } from "react-hook-form";

const DeleteSectionModal = ({ onClose, selectedSection }) => {

    const hook = useDeleteSectionModalHook({ onClose, selectedSection });
    
    return (
        <ModalMain>
            <FormProvider {...hook.form.sectionForm}>
                <DeleteSectionForm>
                    
                        <div className={"menu-content"}>
                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Deletar Sessão</FontGreyDarkMediumTitle>
                                </div>
                                <FontGreyDarkFullRegular>Tem certeza que deseja excluir a Sessão <FontGreyDarkFullBold> {selectedSection?.name}</FontGreyDarkFullBold>?</FontGreyDarkFullRegular>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} onClick={hook.form.onSubmit}> {hook.form.isLoading ? 'Excluindo...' : 'Excluir'} </PrimaryButton>
                            </div>
                        </div>
            
                </DeleteSectionForm>
            </FormProvider>
        </ModalMain>
    );
}

export default DeleteSectionModal;