export function setShowDashboard(){
  return {
    type : "SET_SHOW_DASHBOARD"
  }
}

export function setShowReports(){
  return {
    type : "SET_SHOW_REPORTS"
  }
}

export function setShowDegradations(){
  return {
    type : "SET_SHOW_DEGRADATIONS"
  }
}

export function setShowConnections(){
  return {
    type : "SET_SHOW_CONNECTIONS"
  }
}

export function setShowScripts(){
  return {
    type : "SET_SHOW_SCRIPTS"
  }
}

export function setShowNotifications(){
  return {
    type : "SET_SHOW_NOTIFICATIONS"
  }
}

export function setMenuType(menuType){
  return {
    type : "SET_TYPE",
    menuType
  }
}
