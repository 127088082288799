import MenuItem from '../MenuItem';
import { MenuContainer } from './styled';
import dashboard_icon from '../../../../assets/icons/dashboard.svg';
import reports_icon from '../../../../assets/icons/reports.svg';
import notifications_icon from '../../../../assets/icons/notifications.svg';
import connections_icon from '../../../../assets/icons/connections.svg';
import degradation_icon from '../../../../assets/icons/degradation.svg';
import scripts_icon from '../../../../assets/icons/scripts.svg';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../../../utils/storeUtils';

const Menu = ({ menu, setShowDashboard, setShowNotifications, setShowReports, setShowDegradations, setShowConnections, setShowScripts }) => {
    return (
        <MenuContainer>
            {menu.type === "MAP-PAGE" &&
                <>
                    <MenuItem title={"Dashboard"} icon={dashboard_icon} activateMenuItem={menu.showDashboard} onClick={setShowDashboard} />
                    <MenuItem title={"Relatórios"} icon={reports_icon} activateMenuItem={menu.showReports} onClick={setShowReports} />
                    <MenuItem title={"Notificações"} icon={notifications_icon} activateMenuItem={menu.showNotifications} onClick={setShowNotifications} />
                </>
            }

            {menu.type === "ASSET-PAGE" &&
                <>
                    <MenuItem title={"Dashboard"} icon={dashboard_icon} activateMenuItem={menu.showDashboard} onClick={setShowDashboard} />
                    <MenuItem title={""} icon={degradation_icon} activateMenuItem={menu.showDegradations} onClick={setShowDegradations} />
                    <MenuItem title={"Relatórios"} icon={reports_icon} activateMenuItem={menu.showReports} onClick={setShowReports} />
                    <MenuItem title={""} icon={connections_icon} activateMenuItem={menu.showConnections} onClick={setShowConnections} />
                    <MenuItem title={""} icon={scripts_icon} activateMenuItem={menu.showScripts} onClick={()=>window.open('/relatorio.pdf')}/>
                </>
            }

            {menu.type === "SECTION-PAGE" &&
                <>
                    <MenuItem title={"Dashboard"} icon={dashboard_icon} activateMenuItem={menu.showDashboard} onClick={setShowDashboard} />
                    <MenuItem title={"Relatórios"} icon={reports_icon} activateMenuItem={menu.showReports} onClick={setShowReports} />
                </>
            }


        </MenuContainer>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);