import { responseStatus, useHandleRequest } from "../service/api-services";
import { getDefaultQuery } from "../utils/query-client";

export const anomaliesQueries = {
    EDIT: "ANOMALIES_EDIT",
    CREATE: "ANOMALIES_CREATE",
    DELETE: "ANOMALIES_DELETE",
    GET_ALL: "ANOMALIES_GET_ALL",
    GET_BY_ID: "ANOMALIES_GET_BY_ID",
}


export function useAnomaliesService() {

    const handleRequest = useHandleRequest();

    async function createAnomalies({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/anomalies`, 'POST', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function editAnomalies({ payload, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/anomalies/${payload.id}`, 'PUT', payload);
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function deleteAnomalies({ id, onSuccess }) {
        const { status, data } = await handleRequest.handleRequest(`/anomalies/${id}`, 'DELETE');
        if (status === responseStatus.SUCCESS) {
            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    async function getAllAnomalies({ search, page }) {
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/anomalies/${query}`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getWithoutPositionAnomalies({ search, fkAssetId, page }) {
        const filters = [{ search }, { fkAssetId}];
        let query = getDefaultQuery({ page, filters });
        const { status, data } = await handleRequest.handleRequest(`/anomalies/only/without-position/${query}&includePositioned=true`, 'GET');
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getAnomaliesById({ id }) {
        if (id) {
            const { status, data } = await handleRequest.handleRequest(`/anomalies/${id}`, 'GET');
            if (status === responseStatus.SUCCESS) {
                return data;
            }
        }
    }

    return {
        createAnomalies,
        editAnomalies,
        deleteAnomalies,
        getAllAnomalies,
        getAnomaliesById,
        getWithoutPositionAnomalies
    }
}
