import axios from 'axios';
import { LocalClientToken } from '../utils/local-client-token';

export const baseUrl = window.location.hostname === 'localhost' || window.location.hostname === 'dev-sis.tauin.com'
    ? 'https://dev-api.tauin.com' 
    : 'https://dev-api.tauin.com';

const api = axios.create({
  baseURL: `${baseUrl}/api/v1`
});

api.interceptors.request.use(
  (config) => {
      const token = LocalClientToken.getStoredToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

export default api;