import { useNavigate } from "react-router-dom";
import { metricsQueries, useMetricsService } from "../../../services/metricsService";
import { useQuery } from "react-query";
import { blobToArrayBuffer } from "../../../utils/functions";
import { getFromIndexedDB, saveToIndexedDB } from "../../../service/indexedDB";
import * as THREE from 'three';
import { useEffect, useRef, useState } from "react";
import Utils from "../../../utils/utils";
import { STLLoader } from 'three/addons/loaders/STLLoader';
import axios from "axios";
import { baseUrl } from "../../../service/api";
import { assetsQueries, useAssetsService } from "../../../services/assetService";


export function useAssetInformationHook({section}) {
    const [objects, setObjects] = useState();
    const [progress, setProgress] = useState(0);
    const [highlightedObject, setHighlightedObject] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const orbitControls = useRef();
    const camera = useRef();

    const assetService = useAssetsService();
    
    const {
        data: assetData,
        isLoading: isLoadingAsset,
        isRefetching: isRefetchingAsset,
        refetch: refetchAsset
    } = useQuery(assetsQueries.GET_BY_ID, () => assetService.getAssetsById({ id: section.fkAssetId }));
    
    useEffect(() => {
        if (section.fkAssetId && section?.reference3d && assetData) {

            Utils.clear3DObjects(objects, setObjects);

            const tempObjects = [];

            if (section?.reference3d?.section3d?.location) {
                const loader = new STLLoader();

                const loadSTL = async () => {
                    try {
                        const relativePaths = section.reference3d?.section3d.location.split('/')
                        const cacheKey = `${relativePaths[relativePaths.length - 1]}`;
                        let arrayBuffer;

                        const cachedDataBlob = await getFromIndexedDB(cacheKey, `home-${assetData.reference3d.fkAsset3DItemId}`);

                        if (cachedDataBlob) {
                            arrayBuffer = await blobToArrayBuffer(cachedDataBlob);
                        } else {
                            const response = await axios.get(`${baseUrl}/${section?.reference3d?.section3d?.location}`, {
                                responseType: 'arraybuffer',
                                onDownloadProgress: (progressEvent) => {
                                    setProgress((progressEvent.loaded / progressEvent.total) * 100);
                                }
                            });
                            arrayBuffer = response.data;
                            await saveToIndexedDB(cacheKey, new Blob([arrayBuffer], `home-${assetData.reference3d.fkAsset3DItemId}`));
                        }

                        if (arrayBuffer) {

                            const geometry = loader.parse(arrayBuffer);
                            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
                            const center = new THREE.Vector3();
                            box.getCenter(center);

                            const size = new THREE.Vector3();
                            box.getSize(size);
                            const maxDim = Math.max(size.x, size.y, size.z);

                            const scaleFactor = (1 / maxDim);
                            geometry.scale(scaleFactor, scaleFactor, scaleFactor);

                            const material = new THREE.MeshStandardMaterial({ color: "#cccccc" });
                            const stlMesh = new THREE.Mesh(geometry, material);
                            stlMesh.position.set(-center.x * scaleFactor, -center.y * scaleFactor, -center.z * scaleFactor);
                            stlMesh.castShadow = true;
                            stlMesh.receiveShadow = true;

                            tempObjects.push(
                                { section: section.reference3d.section3d, object: stlMesh },
                            );

                            setObjects(tempObjects);

                            setRefresh(false);
                        }
                    } catch (error) {
                        console.error(`Failed to load STL file for section ${section?.reference3d?.section3d?.token}:`, error);
                    }
                };

                loadSTL();
            }
        }
    }, [section,assetData]);

    return {
        section: {
            isLoading: refresh || isLoadingAsset || isRefetchingAsset,
        },
        config3d: {
            orbitControls,
            objects,
            highlightedObject,
            progress,
            camera
        }
    }
} 