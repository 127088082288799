import Guide from '../../Guide';
import { ReportsContainer, TabItem, TabSelect } from './styled';
import CardReportProdutivity from '../CardReportsProdutivity';
import CardReportFinacial from '../CardReportsFinancial';
import CardReportAssets from '../CardReportsAssets';
import { useCardReportHook } from './hook';
import open_icon from '../../../../assets/icons/open.svg';

const CardReports = () => {
    const hook = useCardReportHook();

    return (
        <ReportsContainer id="reports">
            <Guide showInfo={false} />

            <div className="tabs">
                <TabSelect>
                    {hook.tabItems.map(item => (
                        <TabItem onClick={() => hook.setSelectedTab(item)} active={item === hook.selectedTab ? "true" : "false"}>
                            {item}
                        </TabItem>
                    ))}
                </TabSelect>

                {/* <button className='laudos-button' onClick={()=>hook.navigate('/reports')}>
                    <span>Laudos</span>
                    <img src={open_icon} alt="download" />
                </button> */}
            </div>

            {hook.selectedTab === hook.tabItems[0] && <CardReportProdutivity />}

            {hook.selectedTab === hook.tabItems[1] && <CardReportAssets />}

            {hook.selectedTab === hook.tabItems[2] && <CardReportFinacial />}


        </ReportsContainer>

    )
}

export default CardReports;